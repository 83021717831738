import React, { useEffect } from 'react'
import { StaticImage } from "gatsby-plugin-image"
import SliderAccueil from '../components/slider-accueil'
import Layout from '../components/layout'
import Seo from '../components/seo'
import './accueil.scss'
// import data from '../components/counters.json'
import dataEntreprise from '../components/entreprise.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import moment from 'moment';

export default function Accueil() {

    // const Count = ({ data, variants, i }) => {
    //     const { number, duration } = data
    //     const [count, setCount] = useState("0")
    //     const icons = require("@fortawesome/free-solid-svg-icons");

    //     useEffect(() => {
    //         let start = 0;
    //         const end = parseInt(number.substring(0, 3))
    //         if (start === end) return;

    //         let totalMilSecDur = parseInt(duration);
    //         let incrementTime = (totalMilSecDur / end) * 100;

    //         let timer = setInterval(() => {
    //             start += 1;
    //             setCount(String(start) + number.substring(3))
    //             if (start === end) clearInterval(timer)
    //         }, incrementTime);

    //     }, [number, duration]);

    //     const controls = useAnimation();
    //     const { ref, inView } = useInView({});

    //     useEffect(() => {
    //         if (inView) {
    //             controls.start('visible');
    //         }
    //         if (!inView) {
    //             controls.start('hidden');
    //         }
    //     }, [controls, inView]);

    //     return (
    //         <motion.li
    //             className="counter"
    //             ref={ref}
    //             initial="hidden"
    //             custom={i}
    //             animate={controls}
    //             variants={variants}
    //         >
    //             <FontAwesomeIcon icon={icons[data.icon]} />
    //             <h2 className="counters">{count}</h2>
    //             <p>{data.text}</p>
    //         </motion.li>
    //     );
    // }

    const Entreprise = ({ dataEntreprise, variants, i }) => {

        const controls = useAnimation();
        const { ref, inView } = useInView({});
        const icons = require("@fortawesome/free-solid-svg-icons");

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
            if (!inView) {
                controls.start('hidden');
            }
        }, [controls, inView]);

        return (
            <motion.li
                className="blurb width50"
                ref={ref}
                initial="hidden"
                custom={i}
                animate={controls}
                variants={variants}
            >
                <div>
                    <FontAwesomeIcon icon={icons[dataEntreprise.icon]} />
                </div>
                <div>
                    <h3>{dataEntreprise.title}</h3>
                    <p>{dataEntreprise.text}</p>
                </div>
            </motion.li>
        )
    }

    const Section1 = () => {
        const sinceYear = moment([1990, 1, 1]).locale("fr").fromNow(true)

        const controls = useAnimation();
        const { ref, inView } = useInView({});

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
            if (!inView) {
                controls.start('hidden');
            }
        }, [controls, inView]);

        const toRight = {
            hidden: { opacity: 0, x: -40 },
            visible: { opacity: 1, x: 0 },
        }

        const toLeft = {
            hidden: { opacity: 0, x: 40 },
            visible: { opacity: 1, x: 0 },
        }

        return (
            <section className="section black" ref={ref}>
                <div className="flex_wrap_center container">
                    <div className="width50 trigger-element">
                        <motion.h2
                            className="after_title"
                            initial="hidden"
                            animate={controls}
                            variants={toRight}
                            transition={{ ease: "easeOut", duration: .5, delay: 0.2 }}
                        >
                            Garage Avocat
                        </motion.h2>
                        <motion.p
                            className="before_title"
                            initial="hidden"
                            animate={controls}
                            variants={toRight}
                            transition={{ ease: "easeOut", duration: .5, delay: 0.3 }}
                        >
                            Historique
                        </motion.p>
                        <motion.p
                            className="text"
                            initial="hidden"
                            animate={controls}
                            variants={toRight}
                            transition={{ ease: "easeOut", duration: .5, delay: .4 }}
                        >
                            Situé à Beaufort sur Doron et aux Saisies, le garage AVOCAT est une entreprise familiale depuis 1990, {sinceYear} d'expérience, attaché  à instaurer une relation privilégiée avec nos clients, en leur proposant des interventions de qualité au meilleur prix.
                        </motion.p>
                        <motion.p
                            className="before_title"
                            initial="hidden"
                            animate={controls}
                            variants={toRight}
                            transition={{ ease: "easeOut", duration: .5, delay: 0.5 }}
                        >
                            Notre savoir faire
                        </motion.p>
                        <motion.p
                            className="text"
                            initial="hidden"
                            animate={controls}
                            variants={toRight}
                            transition={{ ease: "easeOut", duration: .5, delay: .6 }}
                        >
                            Notre équipe est composée de professionnels qualifiés qui se forme régulièrement aux évolutions technologiques. Notre équipe assure le suivi de vos véhicules toutes marques avec conscience et professionnalisme et vous conseille tout au long de l'année.<br />
                            Relation de confiance et qualité du travail, ainsi qu'une tarification juste, marque la volonté de bien vous servir.<br />
                            Garage agrée  par les compagnies d'assurance.
                        </motion.p>
                    </div>
                    <div className="width50">
                        <motion.div
                            initial="hidden"
                            animate={controls}
                            variants={toLeft}
                            transition={{ ease: "easeOut", duration: .5, delay: .7 }}
                        >
                            <StaticImage
                                src="../images/DSC00448.jpg"
                                alt=""
                                placeholder="blurred"
                            />
                        </motion.div>
                    </div>
                </div>
            </section>
        )
    }

    // const SectionCount = () => {

    //     const list = {
    //         hidden: { opacity: 0 },
    //         visible: {
    //             opacity: 1,
    //             transition: {
    //                 delayChildren: 0.3,
    //                 staggerChildren: 0.2
    //             }
    //         }
    //     }

    //     const variants = {
    //         visible: i => ({
    //             opacity: 1,
    //             y: 0,
    //             transition: {
    //                 delay: i * 0.1,
    //             },
    //         }),
    //         hidden: { opacity: 0, y: 20 },
    //     }

    //     return (
    //         <section className="section-counters">
    //             <motion.ul
    //                 className="all-counters"
    //                 initial="hidden"
    //                 animate="visible"
    //                 variants={list}
    //             >
    //                 {data.counts.map((count, i) =>
    //                     <Count
    //                         key={count.id}
    //                         data={count}
    //                         variants={variants}
    //                         i={i}
    //                     />
    //                 )}
    //             </motion.ul>
    //         </section>
    //     )
    // }

    const SectionEntreprise = () => {

        const controls = useAnimation();
        const { ref, inView } = useInView({});

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
            if (!inView) {
                controls.start('hidden');
            }
        }, [controls, inView]);

        const toRight = {
            hidden: { opacity: 0, x: -40 },
            visible: { opacity: 1, x: 0 },
        }

        const variants = {
            visible: i => ({
                opacity: 1,
                y: 0,
                transition: {
                    delay: i * 0.1,
                },
            }),
            hidden: { opacity: 0, y: 20 },
        }

        return (
            <section className="section-entreprise" ref={ref}>
                <motion.h2
                    className="after_title_center"
                    style={{ textAlign: "center" }}
                    initial="hidden"
                    animate={controls}
                    variants={toRight}
                    transition={{ ease: "easeOut", duration: .5, delay: 0.2 }}
                >
                    L'entreprise
                </motion.h2>
                <div className="flex_wrap_center container">
                    <motion.div
                        className="width33"
                        initial="hidden"
                        animate={controls}
                        variants={toRight}
                        transition={{ ease: "easeOut", duration: .5, delay: 0.4 }}
                    >
                        <StaticImage
                            src="../images/DSC00443-2.jpg"
                            alt=""
                            placeholder="blurred"
                        />
                    </motion.div>
                    <ul className="blurbs width66">
                        {dataEntreprise.blurbs.map((blurbs, i) =>
                            <Entreprise
                                key={blurbs.id}
                                dataEntreprise={blurbs}
                                variants={variants}
                                i={i}
                            />
                        )}
                    </ul>
                </div>
            </section>
        )
    }

    return (
        <Layout>
            <Seo title="Accueil" />
            <div id="accueil">

                <SliderAccueil />

                <Section1 />

                {/* <SectionCount /> */}

                <SectionEntreprise />

            </div>
        </Layout>
    )
}