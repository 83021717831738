import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import 'typeface-abril-fatface'
import './slider-accueil.scss'

export default function SliderAccueil() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        autoplaySpeed: 9000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: false,
        arrows: true,
        fade: true
    }

    return (
        <div className="slider_accueil">
            <Slider {...settings}>

                <div className="slide relative">
                    <StaticImage
                        src="../images/main-slider/image-1.jpg"
                        alt="Logo Garage AVOCAT"
                        placeholder="blurred"
                    />
                    <div className="overlay_absolute_6" />
                </div>

                <div className="slide relative">
                    <StaticImage
                        src="../images/main-slider/image-2.jpg"
                        alt="Logo Garage AVOCAT"
                        placeholder="blurred"
                    />
                    <div className="overlay_absolute_6" />
                </div>

                <div className="slide relative">
                    <StaticImage
                        src="../images/main-slider/image-3.jpg"
                        alt="Logo Garage AVOCAT"
                        placeholder="blurred"
                    />
                    <div className="overlay_absolute_6" />
                </div>

            </Slider>
            <div className="title-slider relative text_center">
                <div>
                    <StaticImage
                        src="../images/logob.png"
                        alt="Logo Garage AVOCAT"
                        placeholder="blurred"
                    />
                </div>
                <h1>Gge AVOCAT</h1>
            </div>
        </div>
    )
}